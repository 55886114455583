import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import VSCode from "../components/vscode";

const SunIcon = () => (
  <motion.svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#FFD700"
    className="absolute bottom-2 right-2"
    animate={{
      y: [0, -4, 0],
      rotate: [0, 360],
    }}
    transition={{
      y: { duration: 2, repeat: Infinity },
      rotate: { duration: 20, repeat: Infinity },
    }}
  >
    <circle cx="12" cy="12" r="5" />
    <g>
      {[...Array(8)].map((_, i) => (
        <line
          key={i}
          x1="12"
          y1="4"
          x2="12"
          y2="2"
          stroke="#FFD700"
          strokeWidth="2"
          transform={`rotate(${i * 45} 12 12)`}
        />
      ))}
    </g>
  </motion.svg>
);

const MoonIcon = () => (
  <motion.svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#FFFFFF"
    className="absolute bottom-2 right-2"
    animate={{
      y: [0, -4, 0],
      rotate: [0, 360],
    }}
    transition={{
      y: { duration: 2, repeat: Infinity },
      rotate: { duration: 20, repeat: Infinity },
    }}
  >
    <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
  </motion.svg>
);

const TimeWidget = () => {
  const [time, setTime] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })));
  let isNight = time.getHours() >= 19 || time.getHours() <= 6;

  useEffect(() => {
    const timer = setInterval(() => {
      const germanTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" });
      setTime(new Date(germanTime));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formattedTime = time.toLocaleTimeString("de-DE", {
    timeZone: "Europe/Berlin",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      className={`h-32 w-32 rounded-3xl flex items-center justify-center text-white shadow-lg relative overflow-hidden hover:scale-105 duration-100 p-5 m-2 ${
        isNight ? "bg-[#2C2C54]" : "bg-[#1D96FF]"
      }`}
    >
      <div className="text-center z-20">
        <p className="text-2xl">{formattedTime}</p>
        <p className="text-base">in Germany</p>
      </div>
      {isNight ? <MoonIcon /> : <SunIcon />}
    </div>
  );
};

const BirthdayWidget = ({ daysUntilBirthday }) => {
  return (
    <div className="h-32 w-32 bg-[#23224C] rounded-3xl flex items-center justify-center text-[#7983EA] shadow-lg hover:scale-105 duration-100 p-5 m-2">
      <div className="text-center">
        <p className="text-2xl">
          {daysUntilBirthday} <span className="text-base">days</span>
        </p>{" "}
        <p className="text-base">until birthday</p>
      </div>
    </div>
  );
};

const GitHubWidget = () => {
  return (
    <a
      className="h-48 w-80 bg-[url('../styles/matrix.gif')] bg-[length:100%_100%] bg-repeat rounded-3xl flex items-center justify-center text-white shadow-lg hover:scale-105 duration-100 m-2"
      href="https://github.com/noemtdotdev"
    >
      <div className="relative min-w-full min-h-full p-5">
        <svg
          width="32"
          height="32"
          viewBox="0 0 1024 1024"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-5 left-5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
            transform="scale(64)"
            fill="white"
          />
        </svg>
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="text-xl opacity-50 transition duration-500 group-hover:opacity-100 absolute top-5 right-5"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          ></path>
        </svg>
        <h1 className="absolute bottom-5 left-5">GitHub</h1>
      </div>
    </a>
  );
};

const Index = () => {
  const calculateDaysUntilBirthday = () => {
    const today = new Date();
    const birthday = new Date(today.getFullYear(), 2, 12);

    if (today > birthday) {
      birthday.setFullYear(today.getFullYear() + 1);
    }

    const diffInMs = birthday - today;
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  };

  return (
    <main className="min-h-screen justify-center items-center flex">
      <div className="hidden xl:block">
        <div className="macbook">
          <div className="display">
            <div className="screen">
              <main className="flex-grow flex flex-wrap justify-center text-center mt-5">
                <TimeWidget />
                <BirthdayWidget daysUntilBirthday={calculateDaysUntilBirthday()} />
                <GitHubWidget />
                <VSCode />
              </main>
            </div>
          </div>
          <div className="base">
            <div className="indent"></div>
          </div>
          <div className="bottom"></div>
        </div>
      </div>

      <div className="xl:hidden flex items-center justify-center p-4">
        <p className="text-center text-white text-2xl">
          This website is only designed for desktop devices
        </p>
      </div>
    </main>
  );
};

export default Index;
