import React, { useState } from "react";
import { motion, useDragControls } from "framer-motion";

const IconWidget = () => {
    return (
      <img src="/assets/icon.webp" alt="icon" className="rounded-xl hover:scale-105 duration-100 m-2 w-32 h-32"/>
    );
  }

const VSCode = () => {
  const dragControls = useDragControls();
  const [isMaximized, setIsMaximized] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  function getAge() {
    const today = new Date();
    const birthDate = new Date("2007-03-12");
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }

    return age;
  }

  return (
    <>
      {!isClosed ? (
        <motion.div
          className="site-wrap window rounded-xl overflow-hidden border border-gray-800/30"
          drag={!isMaximized}
          dragControls={dragControls}
          dragMomentum={false}
        >
          <div className="window__bar bg-gray-900/80 backdrop-blur-sm h-12 flex items-center px-4 border-b-2 border-solid border-[#1E1E1E] mb-2"
            onPointerDown={(e) => dragControls.start(e)}>
            <div className="flex space-x-2">
              <button 
                className="w-3 h-3 bg-red-500 rounded-full hover:brightness-90"
                onClick={() => {setIsClosed(true)}} 
              />
              <button className="w-3 h-3 bg-yellow-500 rounded-full hover:brightness-90" />
              <button
                className="w-3 h-3 bg-green-500 rounded-full hover:brightness-90"
                onClick={() => setIsMaximized(!isMaximized)}
              />
            </div>

        <span className="window__title flex-1 text-center text-sm text-gray-400 flex items-center justify-center gap-2">
          README.md
          <span className="h-[1px] w-4 bg-gray-400" />
          nodejs
        </span>
      </div>

      <div className="text-left text-white flex gap-2 px-2 py-1">
        <svg
          fill="#ffffff"
          width="800px"
          height="800px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
        >
          <path d="M14.481 14.015c-.238 0-.393.021-.483.042v3.089c.091.021.237.021.371.021.966.007 1.597-.525 1.597-1.653.007-.981-.568-1.499-1.485-1.499z" />
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zm-2.934 15.951-.07-1.807a53.142 53.142 0 0 1-.042-1.94h-.021a26.098 26.098 0 0 1-.525 1.828l-.574 1.842H9l-.504-1.828a21.996 21.996 0 0 1-.428-1.842h-.013c-.028.638-.049 1.366-.084 1.954l-.084 1.793h-.988L7.2 13.23h1.422l.462 1.576c.147.546.295 1.135.399 1.688h.021a39.87 39.87 0 0 1 .448-1.694l.504-1.569h1.394l.26 4.721h-1.044zm5.25-.56c-.498.413-1.253.609-2.178.609a9.27 9.27 0 0 1-1.212-.07v-4.636a9.535 9.535 0 0 1 1.443-.099c.896 0 1.478.161 1.933.505.49.364.799.945.799 1.778 0 .904-.33 1.528-.785 1.913zM14 9h-1V4l5 5h-4z" />
        </svg>
        <span className="text-sm text-gray-400">README.md</span>
      </div>

      <div className="content text-left leading-relaxed px-4 py-2">
        Hey There! 👋
        <br />
        My name is Dennis, I'm a {getAge()}y/o software developer from Germany.
        <br />
        <hr className="my-1" />
        I started programming in Python during the first lockdown in 2020 (at
        the age of 14) and quickly fell in love with it. Now I am in high
        school, finishing up my A-levels.
        <br />
        <hr className="my-1" />
        Over the years I slowly started adding in things such as JavaScript for
        Web Development or Java just for fun.
        <br />
        <hr className="my-1" />
        Programming aside, I love to go to the gym, play video games or spending time with friends. Basically just your average teenager.
        <br />
        <hr className="my-1" />
        and a cute little cat 🐱
        <IconWidget />

      </div>
    </motion.div>
      ) : (<></>)}
    </>
  );
};

export default VSCode;
